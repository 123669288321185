export class SchoolSetting {
  constructor(
    public id: string,
    public schoolId: string,
    public mode: SchoolSettingMode,
    public firstPretestId?: string
  ) {}
  public static parse(json: { [key: string]: any }): SchoolSetting {
    const school = new SchoolSetting(
      json['id'],
      json['schoolId'],
      json['mode'],
      json['firstPretestId']
    );

    return school;
  }
}

export enum SchoolSettingMode {
  School = 'SCHOOL',
  Corporate = 'CORPORATE',
}
