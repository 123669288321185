import Markdown from 'react-markdown';

interface MarkdownContentProps {
  content: string;
}
export const MarkdownContent: React.FC<MarkdownContentProps> = ({
  content,
}: MarkdownContentProps) => {
  return (
    <div>
      <Markdown>{content}</Markdown>
    </div>
  );
};
