import { postRequest } from './utils';

const baseAiUrl =
  'https://edspeak-ai-vg5zhtok2a-as.a.run.app';

export const gradeTestInstance = async (testId: string) => {
  try {
    const fetchUrl = new URL(`/tests/${testId}/grade`, baseAiUrl);
    const result = await postRequest(fetchUrl);
    console.log(result);
    return;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
