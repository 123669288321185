import React, { createContext, useContext } from 'react';
import * as assessmentApiClient from '../../api/assessment';
import * as schoolApiClient from '../../api/school';
import { useNavigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../../router';
import { AccessCode } from '../../model/accessCode';
import { School } from '../../model/school';
import { Tester } from '../../model/tester';
import { PreTestQuestionAnswer, PreTestQuestionSet } from '../../model/preTestQuestion';
import { SpeakingTestQuestion } from '../../model/speakingTestQuestion';
import { getLatestSpeakingTestQuestionIndex, getRouteByTestStatus } from './helpers';
import useAssessmentAppStorage from '../../utils/useAssessmentAppStorage';
import { Loading } from '../../components/base';
import { MimeTypes } from '../../routes/assessmentApp/RecordingButton/useMediaRecorder';
import { SchoolSetting } from '../../model/schoolSetting';

interface ContextType {
  accessCode?: AccessCode;
  testId?: string;
  tester?: Tester;
  school?: School;
  schoolSetting?: SchoolSetting;
  questionNumber: number;
  currentSpeakingTestQuestionIndex?: number;
  preTestQuestionSet?: PreTestQuestionSet;
  speakingTestQuestions?: SpeakingTestQuestion[];
  setQuestionNumber: (question: number) => void;
  startPreTestQuiz: () => void;
  submitPreTestQuestionSet: (
    preTestQuestionSetId: string,
    questionAnswers: PreTestQuestionAnswer[]
  ) => Promise<boolean>;
  startOrContinueSpeakingTestQuiz: () => void;
  submitSpeakingTestQuestion: (
    speakingTestQuestionId: string,
    audioBlob: Blob,
    mimeType: MimeTypes
  ) => Promise<boolean>;
  cancelTest: () => void;
  isSpeakingTestReady: boolean;
  finishTestMic: () => void;
  isMicrophoneTested: () => boolean;
  clearSession: () => void;
  checkHasOnlySpeakingTest: () => boolean;
}

const AssessmentContext = createContext<ContextType>({
  questionNumber: 1,
  isSpeakingTestReady: false,
  setQuestionNumber: (): void => {
    throw new Error('setQuestion Function not implemented.');
  },
  startPreTestQuiz: (): void => {
    throw new Error('startPreTestQuiz Function not implemented');
  },
  submitPreTestQuestionSet: (): Promise<boolean> => {
    throw new Error('submitPreTestQuestionSet Function not implemented');
  },
  startOrContinueSpeakingTestQuiz: (): void => {
    throw new Error('startOrContinueSpeakingTestQuiz Function not implemented');
  },
  submitSpeakingTestQuestion: (): Promise<boolean> => {
    throw new Error('submitTestQuestion Function not implemented');
  },
  cancelTest: (): void => {
    throw new Error('cancelTest Function not implemented');
  },
  finishTestMic: (): void => {
    throw new Error('finishTestMic Function not implemented');
  },
  isMicrophoneTested: (): boolean => {
    throw new Error('isMicrophoneTested Function not implemented');
  },
  clearSession: (): boolean => {
    throw new Error('clearSession Function not implemented');
  },
  checkHasOnlySpeakingTest: (): boolean => {
    throw new Error('checkHasOnlySpeakingTest Function not implemented');
  },
});

export const AssessmentContextProvider = ({
  testId,
  children,
}: {
  testId: string;
  children: React.ReactNode;
}) => {
  const [appInitialized, setAppInitialized] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState<AccessCode>();
  const [tester, setTester] = React.useState<Tester>();
  const [school, setSchool] = React.useState<School>();
  const [schoolSetting, setSchoolSetting] = React.useState<SchoolSetting>();
  const [questionNumber, setQuestionNumber] = React.useState<number>(1);
  const [preTestQuestionSet, setPreTestQuestionSet] = React.useState<PreTestQuestionSet>();
  const [speakingTestQuestions, setSpeakingTestQuestions] =
    React.useState<SpeakingTestQuestion[]>();
  const [currentSpeakingTestQuestionIndex, setCurrentSpeakingTestQuestionIndex] =
    React.useState<number>(0);
  const navigate = useNavigate();
  const { clearTestId, setMicrophoneChecked, getMicrophoneChecked, clearMicrophoneChecked } =
    useAssessmentAppStorage();

  React.useEffect(() => {
    const initializeData = async () => {
      try {
        const { accessCodeId, testerId, status } = await assessmentApiClient.getTestById(testId);
        const [accessCode, tester] = await Promise.all([
          assessmentApiClient.getAccessCodeById(accessCodeId),
          assessmentApiClient.getTesterById(testerId),
        ]);
        const [school, schoolSetting] = await Promise.all([
          assessmentApiClient.getSchoolById(tester.schoolId),
          schoolApiClient.getSchoolSetting(tester.schoolId),
        ]);
        setAccessCode(accessCode);
        setTester(tester);
        setSchool(school);
        setSchoolSetting(schoolSetting);
        setAppInitialized(true);

        const url = getRouteByTestStatus(status, isMicrophoneTested());
        navigate(url);
      } catch (error) {
        console.error(error);
      }
    };
    initializeData();
  }, []);

  const startPreTestQuiz = async () => {
    try {
      const preTestQuestionSet = await assessmentApiClient.startPreTestQuiz(testId);
      setPreTestQuestionSet(preTestQuestionSet);
      navigate(assessmentAppRoutes.AssessmentAppPreTestQuiz.getRoute());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const submitPreTestQuestionSet = async (
    preTestQuestionSetId: string,
    questionAnswers: PreTestQuestionAnswer[]
  ): Promise<boolean> => {
    try {
      const { completed, questionSet } = await assessmentApiClient.submitPreTestQuestionSet(
        testId,
        preTestQuestionSetId,
        questionAnswers
      );
      if (!completed) {
        setPreTestQuestionSet(questionSet);
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const finishTestMic = () => {
    setMicrophoneChecked();
  };

  const isMicrophoneTested = () => {
    return getMicrophoneChecked();
  };

  /**
   * Get speakingTestQuestions of the test
   * Set questions state
   * Set currentQuestionIndex
   */
  const startOrContinueSpeakingTestQuiz = async () => {
    try {
      const questions = await getSpeakingQuestions();
      const latestQuestionIndex = getLatestSpeakingTestQuestionIndex(questions);
      setCurrentSpeakingTestQuestionIndex(latestQuestionIndex);

      navigate(assessmentAppRoutes.AssessmentAppSpeakingTestQuizInstruction.getRoute());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getSpeakingQuestions = async () => {
    try {
      const questions = await assessmentApiClient.getSpeakingTestQuestions(testId);
      setSpeakingTestQuestions(questions);
      return questions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const submitSpeakingTestQuestion = async (
    speakingTestQuestionId: string,
    audioBlob: Blob,
    mimeType: MimeTypes
  ): Promise<boolean> => {
    try {
      const { completed } = await assessmentApiClient.submitSpeakingTestQuestion(
        testId,
        speakingTestQuestionId,
        audioBlob,
        mimeType
      );
      setSpeakingTestQuestions((questions) => {
        if (questions) {
          const newQuestions = [...questions];
          newQuestions[currentSpeakingTestQuestionIndex].completed = true;
          return newQuestions;
        } else {
          return questions;
        }
      });
      if (!completed) {
        setCurrentSpeakingTestQuestionIndex((curr) => curr + 1);
      }

      return completed;
    } catch (error) {
      console.error(error);
      // TODO: Handle error in view
      alert('Cannot submit answer');
      throw error;
    }
  };

  const cancelTest = async () => {
    try {
      await assessmentApiClient.terminateTestById(testId);
      clearSession();
      navigate(assessmentAppRoutes.AssessmentAppHome.getRoute());
    } catch (error) {
      alert(`Cannot terminate test ${testId}`);
      console.error(error);
    }
  };

  const clearSession = () => {
    clearTestId();
    clearMicrophoneChecked();
  };

  const isSpeakingTestReady = React.useMemo<boolean>(
    () => speakingTestQuestions !== undefined && currentSpeakingTestQuestionIndex !== undefined,
    [speakingTestQuestions, currentSpeakingTestQuestionIndex]
  );

  const checkHasOnlySpeakingTest = () => {
    return !!accessCode?.speakingTestId;
  };

  return (
    <AssessmentContext.Provider
      value={{
        accessCode,
        testId,
        tester,
        school,
        schoolSetting,
        questionNumber,
        preTestQuestionSet,
        speakingTestQuestions,
        currentSpeakingTestQuestionIndex,
        setQuestionNumber,
        startPreTestQuiz,
        submitPreTestQuestionSet,
        startOrContinueSpeakingTestQuiz,
        submitSpeakingTestQuestion,
        cancelTest,
        isSpeakingTestReady,
        finishTestMic,
        isMicrophoneTested,
        clearSession,
        checkHasOnlySpeakingTest,
      }}
    >
      {appInitialized ? children : <Loading />}
    </AssessmentContext.Provider>
  );
};

export const useAssessmentContext = () => useContext(AssessmentContext);
