import { AccessCode, AccessCodeType } from '../../model/accessCode';
import { SchoolSettingMode } from '../../model/schoolSetting';
import { AppUIConfig } from './appUIConfig';
import { CorporateAppUIConfig } from './corporateAppUIConfig';
import { SchoolAppUIConfig } from './schoolAppUIConfig';

const appUIConfigSchoolModeMap: Record<SchoolSettingMode, typeof AppUIConfig> = {
  [SchoolSettingMode.School]: SchoolAppUIConfig,
  [SchoolSettingMode.Corporate]: CorporateAppUIConfig,
};
export const getAppUIConfigBySchoolMode = (mode?: SchoolSettingMode | undefined): AppUIConfig => {
  if (!mode) {
    return new AppUIConfig();
  } else {
    return new appUIConfigSchoolModeMap[mode]();
  }
};

const appUIConfigAccessCodeTypeMap: Record<AccessCodeType, typeof AppUIConfig> = {
  [AccessCodeType.School]: SchoolAppUIConfig,
  [AccessCodeType.Business]: CorporateAppUIConfig,
};
export const getAppUIConfigByAccessCodeType = (
  accessCodeType?: AccessCodeType | undefined
): AppUIConfig => {
  if (!accessCodeType) {
    return new AppUIConfig();
  } else {
    return new appUIConfigAccessCodeTypeMap[accessCodeType]();
  }
};
