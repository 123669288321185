import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Loading, Text, Heading, Chip } from '../../../components/base';
import Icon, { IconName } from '../../../components/base/Icon';
import SubmittingModal from './components/SubmittingModal';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { PreTestQuestionAnswer } from '../../../model/preTestQuestion';
import { assessmentAppRoutes } from '../../../router';
import { toClassNames } from '../../../utils';
import { convertSeconds } from '../../../utils/dateTime';
import useTimer from '../../../utils/useTimer';
import NextModal from './components/NextModal';
import FixedPositionTimer from '../../../components/FixedPositionTimer';
import { useTranslation } from 'react-i18next';
import { PreTestContent } from './components/PreTestContent';
import {
  PretestQuestionContent,
  PretestQuestionContentType,
  QuestionPretestQuestionContent,
} from '../../../model/preTestQuestionContent';

const PreTestQuizV2 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  /**
   * key must be questionId
   */
  const [questionAnswers, setQuestionAnswers] = React.useState<{
    [key: string]: PreTestQuestionAnswer;
  }>({});
  const [nextModalOpen, setNextModalOpen] = React.useState(false);
  const [submittingAnswers, setSubmittingAnswer] = React.useState(false);
  const [testCompleted, setTestCompleted] = React.useState(false);
  const [isTimeup, setIsTimeup] = React.useState(false);

  const { preTestQuestionSet, submitPreTestQuestionSet } = useAssessmentContext();
  const { startTimer, stopTimer, timeLeft, resetTimer } = useTimer({
    onTimeUp: handleTimeUp,
    time: 600,
  });
  const [pageNo, setPageNo] = React.useState(1);

  const contentByPage = React.useMemo(() => {
    const contents = preTestQuestionSet?.contents ?? [];
    return contents.reduce<PretestQuestionContent[][]>(
      (
        acc: PretestQuestionContent[][],
        curr: PretestQuestionContent
      ): PretestQuestionContent[][] => {
        if (curr.type === PretestQuestionContentType.SectionBreak) {
          return [...acc, []];
        } else {
          if (acc.length === 0) {
            return [[curr]];
          } else {
            const lastPageContents = acc.pop() || [];

            return [...acc, [...lastPageContents, curr]];
          }
        }
      },
      []
    );
  }, [preTestQuestionSet]);

  const currentPageContent = React.useMemo(() => {
    return contentByPage[pageNo - 1];
  }, [contentByPage, pageNo]);

  const allQuestions = React.useMemo(() => {
    return (
      preTestQuestionSet?.contents.filter(
        (c: PretestQuestionContent): c is QuestionPretestQuestionContent =>
          QuestionPretestQuestionContent.isType(c)
      ) ?? []
    );
  }, [preTestQuestionSet]);

  React.useEffect(() => {
    resetStateAndStartPreTestSet();
  }, []);

  const resetStateAndStartPreTestSet = () => {
    setQuestionAnswers({});
    setIsTimeup(false);
    resetTimer();
    startTimer();
    window.scrollTo(0, 0);
  };

  const handleSelectChoice = (questionId: string, choiceId: string) => {
    const updatedQuestionAnswers = { ...questionAnswers };
    if (updatedQuestionAnswers[questionId]) {
      updatedQuestionAnswers[questionId].choiceId = choiceId;
    } else {
      updatedQuestionAnswers[questionId] = new PreTestQuestionAnswer(questionId, choiceId);
    }
    setQuestionAnswers(updatedQuestionAnswers);
  };

  const handleSubmitQuestionSet = async () => {
    setSubmittingAnswer(true);
    if (preTestQuestionSet) {
      const completed = await submitPreTestQuestionSet(
        preTestQuestionSet.id,
        Object.values(questionAnswers)
      );
      setSubmittingAnswer(false);
      setTestCompleted(completed);
      setNextModalOpen(true);
    }
  };

  const handleNext = () => {
    if (testCompleted) {
      navigate(assessmentAppRoutes.AssessmentAppSpeakingTestTestMicInstruction.getRoute());
    } else {
      setNextModalOpen(false);
      resetStateAndStartPreTestSet();
    }
  };

  async function handleTimeUp() {
    setIsTimeup(true);
    stopTimer();
    await handleSubmitQuestionSet();
  }

  const handleNextPage = () => {
    if (!isLastPage) {
      setPageNo(pageNo + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePreviousPage = () => {
    if (!isFirstPage) {
      setPageNo(pageNo - 1);
      window.scrollTo(0, 0);
    }
  };

  const canSubmit = React.useMemo(() => {
    return Object.keys(questionAnswers).length === allQuestions.length;
  }, [preTestQuestionSet?.questions.length, questionAnswers]);

  const isLastPage = React.useMemo(() => {
    return pageNo === contentByPage.length;
  }, [pageNo, contentByPage]);

  const isFirstPage = React.useMemo(() => {
    return pageNo === 1;
  }, [pageNo, contentByPage]);

  if (!preTestQuestionSet) {
    return (
      <LayoutCard className="my-10 justify-center items-center flex min-h-[300px]">
        <Loading text={t('assessment_pre_test_quiz_loading_text')} />
      </LayoutCard>
    );
  }

  return (
    <>
      <LayoutCard className="my-10">
        <div>
          <div className="flex justify-between items-center mb-2">
            <Heading variant="h5" is="h1">
              <span className="text-primary-500 mr-2">
                {t('assessment_pre_test_quiz_pre_test_question_text')}
              </span>
              {t('assessment_pre_test_quiz_page_no_text', {
                pageNo: pageNo,
              })}
              <span className="ml-1">
                {t('assessment_pre_test_quiz_total_page_text', {
                  totalPage: contentByPage.length,
                })}
              </span>
            </Heading>

            {timeLeft !== undefined && (
              <>
                <Chip className="bg-red-100">
                  <Icon icon={IconName.Timer} size={'20px'} className="text-red-500 mr-2" />
                  <Text className="text-red-500">
                    {t('assessment_pre_test_quiz_time_text')} {convertSeconds(timeLeft, 'mm:ss')}
                  </Text>
                </Chip>

                {/* Below is a workaround to also display timeLeft when users scroll down */}
                <FixedPositionTimer
                  time={convertSeconds(timeLeft, 'mm:ss')}
                  text={t('assessment_pre_test_quiz_time_text')}
                />
              </>
            )}
          </div>
          <div className="relative w-full pb-1 bg-grey-300 rounded-full mb-8">
            <div
              className={toClassNames([
                'absolute top-0 left-0 bg-primary-500 bottom-0 rounded-full',
              ])}
              style={{
                right: `${100 - (100 * preTestQuestionSet.setNo) / preTestQuestionSet.totalSet}%`,
              }}
            ></div>
          </div>
        </div>

        <div className="h-500px">
          {currentPageContent.map((c: PretestQuestionContent, index) => {
            return (
              <PreTestContent
                key={index}
                content={c}
                onSelectChoice={handleSelectChoice}
                questionAnswers={questionAnswers}
                allQuestions={allQuestions}
              />
            );
          })}
        </div>
        {/* <hr className="border border-grey-300 mb-8 mx-10" /> */}
        <div className="flex justify-between">
          {!isFirstPage ? (
            <Button variant="outlined" size="large" onClick={handlePreviousPage}>
              {t('assessment_pre_test_quiz_previous_page')}
            </Button>
          ) : (
            <div />
          )}

          {isLastPage ? (
            <Button
              variant="primary"
              size="large"
              onClick={handleSubmitQuestionSet}
              disabled={!canSubmit}
            >
              {t('assessment_pre_test_quiz_submit_question')}
            </Button>
          ) : (
            <Button variant="primary" size="large" onClick={handleNextPage}>
              {t('assessment_pre_test_quiz_next_page')}
            </Button>
          )}
        </div>
        {isLastPage && (
          <Text variant="sm" className="text-grey-400 mt-4 flex justify-end">
            {canSubmit
              ? t('assessment_pre_test_quiz_text_bottom')
              : t('assessment_pre_test_quiz_text_bottom_please_answer_all_questions')}
          </Text>
        )}
      </LayoutCard>
      <SubmittingModal open={submittingAnswers} isTimeup={isTimeup} />
      <NextModal
        open={nextModalOpen}
        completed={testCompleted}
        questionSetNo={preTestQuestionSet.setNo}
        onClick={handleNext}
      />
    </>
  );
};

export default PreTestQuizV2;
