import { getRequest, postRequest } from './utils';
import { BASE_URL } from './constant';
import { School } from '../model/school';
import { SchoolSetting } from '../model/schoolSetting';

export const ADMIN_API_PATH_PREFIX = '/schools';

export const getSchoolSetting = async (schoolId: string): Promise<SchoolSetting | undefined> => {
  try {
    const fetchUrl = new URL(`/schools/${schoolId}/setting`, BASE_URL);
    const data = await getRequest(fetchUrl);
    if (data) {
      const schoolSetting = SchoolSetting.parse(data);
      return schoolSetting;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
