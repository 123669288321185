import { CEFRLevel } from '../types/cefr';
import { PreTestQuestionChoice } from './preTestQuestion';

export enum PretestQuestionContentType {
  Question = 'QUESTION',
  SectionBreak = 'SECTION_BREAK',
  TextPrompt = 'TEXT_PROMPT',
}

export class PretestQuestionContent {
  constructor(public type: PretestQuestionContentType) {}

  public static parse(json: { [key: string]: any }): PretestQuestionContent {
    const type = json['type'];
    if (type === PretestQuestionContentType.Question) {
      return QuestionPretestQuestionContent.parse(json);
    } else if (type === PretestQuestionContentType.SectionBreak) {
      return SectionBreakPretestQuestionContent.parse(json);
    } else if (type === PretestQuestionContentType.TextPrompt) {
      return TextPromptPretestQuestionContent.parse(json);
    } else {
      throw new Error('Invalid content type');
    }
  }
}

export class QuestionPretestQuestionContent extends PretestQuestionContent {
  constructor(
    public id: string,
    public type: PretestQuestionContentType.Question,
    public question: string,
    public cefrLevel: CEFRLevel,
    public choices: PreTestQuestionChoice[],
    public correctAnswer: string
  ) {
    super(type);
  }

  public static parse(json: { [key: string]: any }): QuestionPretestQuestionContent {
    const question = new QuestionPretestQuestionContent(
      json['id'],
      json['type'],
      json['question'],
      json['cefrLevel'],
      json['choices'].map((c: PreTestQuestionChoice) => PreTestQuestionChoice.parse(c)),
      json['correctAnswer']
    );
    return question;
  }

  public static isType(value: PretestQuestionContent): value is QuestionPretestQuestionContent {
    return value.type === PretestQuestionContentType.Question;
  }
}

export class SectionBreakPretestQuestionContent extends PretestQuestionContent {
  constructor(public type: PretestQuestionContentType.SectionBreak) {
    super(type);
  }

  public static parse(json: { [key: string]: any }): SectionBreakPretestQuestionContent {
    const sectionBreak = new SectionBreakPretestQuestionContent(json['type']);
    return sectionBreak;
  }

  public static isType(value: PretestQuestionContent): value is SectionBreakPretestQuestionContent {
    return value.type === PretestQuestionContentType.SectionBreak;
  }
}

export class TextPromptPretestQuestionContent extends PretestQuestionContent {
  constructor(public text: string, public type: PretestQuestionContentType.TextPrompt) {
    super(type);
  }

  public static parse(json: { [key: string]: any }): TextPromptPretestQuestionContent {
    const prompt = new TextPromptPretestQuestionContent(json['text'], json['type']);
    return prompt;
  }

  public static isType(value: PretestQuestionContent): value is TextPromptPretestQuestionContent {
    return value.type === PretestQuestionContentType.TextPrompt;
  }
}
