import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Loading, Text, Heading, Chip } from '../../../components/base';
import Icon, { IconName } from '../../../components/base/Icon';
import SubmittingModal from './components/SubmittingModal';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import LayoutCard from '../../../layouts/components/LayoutCard';
import {
  PreTestQuestionAnswer,
} from '../../../model/preTestQuestion';
import { assessmentAppRoutes } from '../../../router';
import { toClassNames } from '../../../utils';
import { convertSeconds } from '../../../utils/dateTime';
import useTimer from '../../../utils/useTimer';
import NextModal from './components/NextModal';
import PreTestQuestion from './components/PreTestQuestion';
import FixedPositionTimer from '../../../components/FixedPositionTimer';
import { useTranslation } from 'react-i18next';

const PreTestQuizV1 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  /**
   * key must be questionId
   */
  const [questionAnswers, setQuestionAnswers] = React.useState<{
    [key: string]: PreTestQuestionAnswer;
  }>({});
  const [nextModalOpen, setNextModalOpen] = React.useState(false);
  const [submittingAnswers, setSubmittingAnswer] = React.useState(false);
  const [testCompleted, setTestCompleted] = React.useState(false);
  const [isTimeup, setIsTimeup] = React.useState(false);

  const { preTestQuestionSet, submitPreTestQuestionSet } = useAssessmentContext();
  const { startTimer, stopTimer, timeLeft, resetTimer } = useTimer({
    onTimeUp: handleTimeUp,
    time: 150,
  });

  React.useEffect(() => {
    resetStateAndStartPreTestSet();
  }, []);

  const resetStateAndStartPreTestSet = () => {
    setQuestionAnswers({});
    setIsTimeup(false);
    resetTimer();
    startTimer();
    window.scrollTo(0, 0);
  };

  const handleSelectChoice = (questionId: string, choiceId: string) => {
    const updatedQuestionAnswers = { ...questionAnswers };
    if (updatedQuestionAnswers[questionId]) {
      updatedQuestionAnswers[questionId].choiceId = choiceId;
    } else {
      updatedQuestionAnswers[questionId] = new PreTestQuestionAnswer(questionId, choiceId);
    }
    setQuestionAnswers(updatedQuestionAnswers);
  };

  const handleSubmitQuestionSet = async () => {
    setSubmittingAnswer(true);
    if (preTestQuestionSet) {
      const completed = await submitPreTestQuestionSet(
        preTestQuestionSet.id,
        Object.values(questionAnswers)
      );
      setSubmittingAnswer(false);
      setTestCompleted(completed);
      setNextModalOpen(true);
    }
  };

  const handleNext = () => {
    if (testCompleted) {
      navigate(assessmentAppRoutes.AssessmentAppSpeakingTestTestMicInstruction.getRoute());
    } else {
      setNextModalOpen(false);
      resetStateAndStartPreTestSet();
    }
  };

  async function handleTimeUp() {
    setIsTimeup(true);
    stopTimer();
    await handleSubmitQuestionSet();
  }

  const canSubmit = React.useMemo(() => {
    return Object.keys(questionAnswers).length === preTestQuestionSet?.questions.length;
  }, [preTestQuestionSet?.questions.length, questionAnswers]);

  if (!preTestQuestionSet) {
    return (
      <LayoutCard className="my-10 justify-center items-center flex min-h-[300px]">
        <Loading text={t('assessment_pre_test_quiz_loading_text')} />
      </LayoutCard>
    );
  }

  return (
    <>
      <LayoutCard className="my-10">
        <div>
          <div className="flex justify-between items-center mb-2">
            <Heading variant="h5" is="h1">
              <span className="text-primary-500 mr-2">
                {t('assessment_pre_test_quiz_pre_test_question_text')}
              </span>
              {t('assessment_pre_test_quiz_pre_test_question_set_no_text', {
                setNo: preTestQuestionSet.setNo,
              })}
              <span className="ml-1">
                {t('assessment_pre_test_quiz_pre_test_question_total_set_text', {
                  totalSet: preTestQuestionSet.totalSet,
                })}
              </span>
            </Heading>

            {timeLeft !== undefined && (
              <>
                <Chip className="bg-red-100">
                  <Icon icon={IconName.Timer} size={'20px'} className="text-red-500 mr-2" />
                  <Text className="text-red-500">
                    {t('assessment_pre_test_quiz_time_text')} {convertSeconds(timeLeft, 'mm:ss')}
                  </Text>
                </Chip>

                {/* Below is a workaround to also display timeLeft when users scroll down */}
                <FixedPositionTimer
                  time={convertSeconds(timeLeft, 'mm:ss')}
                  text={t('assessment_pre_test_quiz_time_text')}
                />
              </>
            )}
          </div>
          <div className="relative w-full pb-1 bg-grey-300 rounded-full mb-8">
            <div
              className={toClassNames([
                'absolute top-0 left-0 bg-primary-500 bottom-0 rounded-full',
              ])}
              style={{
                right: `${100 - (100 * preTestQuestionSet.setNo) / preTestQuestionSet.totalSet}%`,
              }}
            ></div>
          </div>
        </div>

        <div className="h-500px">
          {preTestQuestionSet.questions.map(({ question, choices, id }, index) => {
            return (
              <PreTestQuestion
                key={id}
                questionNo={index + 1}
                question={question}
                choices={choices}
                selectedChoiceId={questionAnswers[id]?.choiceId}
                onSelectChoice={(choiceId: string) => handleSelectChoice(id, choiceId)}
              />
            );
          })}
        </div>
        <hr className="border border-grey-300 mb-8 mx-10" />
        <div className="flex flex-col justify-center items-center">
          <Button
            variant="primary"
            size="large"
            onClick={handleSubmitQuestionSet}
            className="mb-4"
            disabled={!canSubmit}
          >
            {t('assessment_pre_test_quiz_submit_question')}
          </Button>
          <Text variant="sm" className="text-grey-400">
            {t('assessment_pre_test_quiz_text_bottom')}
          </Text>
        </div>
      </LayoutCard>
      <SubmittingModal open={submittingAnswers} isTimeup={isTimeup} />
      <NextModal
        open={nextModalOpen}
        completed={testCompleted}
        questionSetNo={preTestQuestionSet.setNo}
        onClick={handleNext}
      />
    </>
  );
};

export default PreTestQuizV1;
