import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../../../../components/base';
import { PreTestQuestionAnswer, PreTestQuestionChoice } from '../../../../model/preTestQuestion';
import { toClassNames } from '../../../../utils';
import style from './PreTestQuestion.module.css';
import {
  PretestQuestionContent,
  QuestionPretestQuestionContent,
  SectionBreakPretestQuestionContent,
  TextPromptPretestQuestionContent,
} from '../../../../model/preTestQuestionContent';
import PreTestQuestion from './PreTestQuestion';
import { MarkdownContent } from './MarkdownContent';

interface PreTestContentProps {
  content: PretestQuestionContent;
  onSelectChoice: (questionId: string, choiceId: string) => void;
  questionAnswers: { [key: string]: PreTestQuestionAnswer };
  allQuestions: QuestionPretestQuestionContent[];
}
export const PreTestContent = ({
  content,
  onSelectChoice,
  questionAnswers,
  allQuestions,
}: PreTestContentProps) => {
  const { t } = useTranslation('assessment');

  if (QuestionPretestQuestionContent.isType(content)) {
    return (
      <PreTestQuestion
        questionNo={allQuestions.findIndex((q) => q.id === content.id) + 1}
        question={content.question}
        choices={content.choices}
        selectedChoiceId={questionAnswers[content.id]?.choiceId}
        onSelectChoice={(choiceId: string) => onSelectChoice(content.id, choiceId)}
      />
    );
  } else if (TextPromptPretestQuestionContent.isType(content)) {
    return (
      <div className="p-6 rounded-lg bg-primary-50 mb-12">
        <MarkdownContent content={content.text} />
      </div>
    );
  } else {
    return null;
  }
};
