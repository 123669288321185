import { useTranslation } from 'react-i18next';
import { Loading } from '../../../components/base';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { PretestQuestionGrouopVersion } from '../../../model/preTestQuestion';

import PreTestQuizV1 from './PreTestQuizV1';
import PreTestQuizV2 from './PreTestQuizV2';

const PreTestQuiz = () => {
  const { t } = useTranslation('assessment');
  const { preTestQuestionSet } = useAssessmentContext();

  if (!preTestQuestionSet) {
    return (
      <LayoutCard className="my-10 justify-center items-center flex min-h-[300px]">
        <Loading text={t('assessment_pre_test_quiz_loading_text')} />
      </LayoutCard>
    );
  }

  if (preTestQuestionSet.version === PretestQuestionGrouopVersion.V2) {
    return <PreTestQuizV2 />;
  } else {
    return <PreTestQuizV1 />;
  }
};

export default PreTestQuiz;
