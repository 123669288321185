import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Loading, Image, Heading } from '../../../../components/base';
import { useAssessmentContext } from '../../../../contexts/assessmentContext';
import { getAppUIConfigBySchoolMode } from '../../../../configs/appUIConfig/appUIConfigLookup';
import { toClassNames } from '../../../../utils';
import { SchoolSettingMode } from '../../../../model/schoolSetting';

interface SubmittingAnswerModalProps {
  open: boolean;
  isTimeup: boolean;
}

const SubmittingAnswerModal = ({ open, isTimeup }: SubmittingAnswerModalProps) => {
  const { schoolSetting } = useAssessmentContext();
  const appUIConfig = React.useMemo(() => {
    return getAppUIConfigBySchoolMode(schoolSetting?.mode);
  }, [schoolSetting?.mode]);
  const { t } = useTranslation('assessment');
  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Image
          src={isTimeup ? '/assets/images/modal-timeup.png' : appUIConfig.submittingModalImageUrl}
          alt="submitting-modal"
          className={toClassNames([
            'mb-6',
            schoolSetting?.mode === SchoolSettingMode.Corporate ? 'w-[112px]' : 'w-[180px]',
          ])}
        />
        <Heading variant="h1" className="mb-2">
          {isTimeup
            ? t('assessment_speaking_test_quiz_submitting_answer_modal_is_timeup')
            : t('assessment_speaking_test_quiz_submitting_answer_modal_is_not_timeup')}
        </Heading>
        <Loading text={t('assessment_speaking_test_quiz_submitting_answer_modal_loading_text')} />
      </div>
    </Modal>
  );
};

export default SubmittingAnswerModal;
