import React from 'react';
import { LoadingModal } from '../../../components/base';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import * as testApiClient from '../../../api/test';
import { SpeakingTestQuestion } from '../../../model/speakingTestQuestion';
import SpeakingQuestionsTable from './components/SpeakingQuestionsTable';

const SpeakingQuestions = () => {
  const [loading, setLoading] = React.useState(false);
  const [speakingQuestions, setSpeakingQuestions] = React.useState<SpeakingTestQuestion[]>([]);

  React.useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        const speakingQuestions = await testApiClient.getAllSpeakingQuestions();
        setSpeakingQuestions(speakingQuestions);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  return (
    <div>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle=""
        title="Speaking Questions"
        description=""
        className="mb-6"
      />

      <SpeakingQuestionsTable speakingQuestions={speakingQuestions} />

      <LoadingModal open={loading} />
    </div>
  );
};

export default SpeakingQuestions;
