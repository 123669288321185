import { useTranslation } from 'react-i18next';
import { Modal, Text, Image, Heading, Button } from '../../../../components/base';
import { useAssessmentContext } from '../../../../contexts/assessmentContext';
import { getAppUIConfigBySchoolMode } from '../../../../configs/appUIConfig/appUIConfigLookup';
import React from 'react';
import { SchoolSettingMode } from '../../../../model/schoolSetting';
import { toClassNames } from '../../../../utils';

interface NextModalProps {
  open: boolean;
  completed: boolean;
  questionSetNo: number;
  onClick: () => void;
}

const NextModal = ({ open, completed, questionSetNo, onClick }: NextModalProps) => {
  const { schoolSetting } = useAssessmentContext();
  const { t } = useTranslation('assessment');
  const appUIConfig = React.useMemo(() => {
    return getAppUIConfigBySchoolMode(schoolSetting?.mode);
  }, [schoolSetting?.mode]);

  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Image
          src={appUIConfig.modalNextImageUrl}
          alt="next"
          className={toClassNames([
            'mb-6',
            schoolSetting?.mode === SchoolSettingMode.Corporate ? 'w-[112px]' : 'w-[180px]',
          ])}
        />
        <Text variant="md" className="text-grey-500 mb-1">
          {t('assessment_next_modal_text_question_set_no')} {questionSetNo}
        </Text>
        <Heading variant="h2" className="mb-6 text-center">
          {completed
            ? t('assessment_next_modal_heading_completed')
            : t('assessment_next_modal_heading_not_completed')}
        </Heading>
        <Button onClick={onClick} variant="primary">
          {completed
            ? t('assessment_next_modal_button_completed')
            : t('assessment_next_modal_button_not_completed')}
        </Button>
      </div>
    </Modal>
  );
};

export default NextModal;
