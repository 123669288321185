import React from 'react';
import { Button, Loading, LoadingModal, Text } from '../../../components/base';
import LayoutCard from '../../../layouts/components/LayoutCard';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import InstructionContentCardV2 from '../../../components/InstructionContentCardV2';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import ErrorModal from '../../../components/ErrorModal';
import { useNavigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../../../router';
import { useTranslation } from 'react-i18next';
import HeaderText from '../PreTestInstruction/components/HeaderText';
import { IconName } from '../../../components/base/Icon';
import { getSpeakingTestQuestions } from '../../../api/assessment';
import {
  SpeakingTestQuestion,
  SpeakingTestQuestionType,
} from '../../../model/speakingTestQuestion';
import { FaLaptopHouse } from 'react-icons/fa';

interface SpeakingTestData {
  totalQuestions: number;
  questionTypes: Set<SpeakingTestQuestionType>;
  questionTypeCount: number;
  totalTime: number;
}

const questionTypeUIMap: Record<SpeakingTestQuestionType, SpeakingTestQuestionType> = {
  [SpeakingTestQuestionType.OPEN_ENDED]: SpeakingTestQuestionType.OPEN_ENDED,
  [SpeakingTestQuestionType.ELABORATE]: SpeakingTestQuestionType.OPEN_ENDED,
  [SpeakingTestQuestionType.DISCUSSION]: SpeakingTestQuestionType.OPEN_ENDED,
  [SpeakingTestQuestionType.READING]: SpeakingTestQuestionType.READING,
  [SpeakingTestQuestionType.DESCRIBE]: SpeakingTestQuestionType.DESCRIBE,
};

const getDefaultSpeakingTestData = (onlySpeakingTestVersion: boolean): SpeakingTestData => {
  return {
    totalQuestions: onlySpeakingTestVersion ? 6 : 5,
    questionTypeCount: onlySpeakingTestVersion ? 1 : 2,
    questionTypes: onlySpeakingTestVersion
      ? new Set([SpeakingTestQuestionType.READING, SpeakingTestQuestionType.OPEN_ENDED])
      : new Set([
          SpeakingTestQuestionType.READING,
          SpeakingTestQuestionType.OPEN_ENDED,
          SpeakingTestQuestionType.DESCRIBE,
        ]),
    totalTime: 6000,
  };
};

const getSpeakingTestDataFromQuestions = (
  speakingTestQuestions: SpeakingTestQuestion[]
): SpeakingTestData => {
  const totalQuestions = speakingTestQuestions.length;
  let totalTime = 0;
  let questionTypes = new Set<SpeakingTestQuestionType>();

  speakingTestQuestions.forEach((question) => {
    totalTime = totalTime + (question.timeLimit || 0);
    questionTypes.add(questionTypeUIMap[question.type]);
  });

  return {
    totalQuestions,
    totalTime,
    questionTypes,
    questionTypeCount: questionTypes.size,
  };
};

const SpeakingTestInstruction = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  const { startOrContinueSpeakingTestQuiz, checkHasOnlySpeakingTest, testId } =
    useAssessmentContext();
  const [speakingTestData, setSpeakingTestData] = React.useState<SpeakingTestData>(
    getDefaultSpeakingTestData(checkHasOnlySpeakingTest())
  );
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [initializing, setInitializing] = React.useState(false);

  React.useEffect(() => {
    const getSpeakingTestData = async () => {
      setInitializing(true);
      const speakingTestQuestions = testId ? await getSpeakingTestQuestions(testId) : undefined;
      if (!speakingTestQuestions) {
        const data = getDefaultSpeakingTestData(checkHasOnlySpeakingTest());
        setSpeakingTestData(data);
      } else {
        const data = getSpeakingTestDataFromQuestions(speakingTestQuestions);
        setSpeakingTestData(data);
      }
      setInitializing(false);
    };

    getSpeakingTestData();
  }, [testId, getDefaultSpeakingTestData]);

  const handleStartSpeakingTest = async () => {
    setLoading(true);
    try {
      await startOrContinueSpeakingTestQuiz();
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  const handleErrorModalButtonClick = () => {
    setError(false);
  };

  const handleErrorModalSecondaryButtonClick = () => {
    navigate(assessmentAppRoutes.AssessmentAppHome.getRoute());
  };

  const getPartNo = () => {
    return checkHasOnlySpeakingTest() ? '1' : '2';
  };

  return (
    <LayoutCard className="my-10 flex flex-col items-center">
      {initializing ? (
        <Loading />
      ) : (
        <>
          <CardHeader
            variant={CardHeaderVariant.PRIMARY}
            subtitle={t('assessment_speaking_test_instruction_cardheader_subtitle', {
              no: getPartNo(),
            })}
            title={t('assessment_speaking_test_instruction_cardheader_title')}
            description={t('assessment_speaking_test_instruction_cardheader_description')}
            className="mb-6"
          />
          <div className="flex flex-row gap-6 mb-4">
            {/* Workaround for BU */}
            <HeaderText
              icon={IconName.SquareText}
              title={t('assessment_speaking_test_instruction_total_question_title', {
                questionCount: speakingTestData.totalQuestions,
              })}
              subtitle={t('assessment_speaking_test_instruction_total_question_subtitle')}
            />
            <HeaderText
              icon={IconName.Timer}
              title={t('assessment_speaking_test_instruction_test_duration_title')}
              subtitle={t('assessment_speaking_test_instruction_test_duration_subtitle')}
            />
          </div>
          <Text className="text-gray-600 font-bold mb-4">
            {/* Workaround for BU */}
            {t('assessment_speaking_test_instruction_text_description', {
              sectionCount: speakingTestData.questionTypeCount,
            })}
          </Text>
          <div className="w-full md:w-fit md:flex justify-center mb-6 rounded-xl border-2 border-gray-200 py-4">
            {speakingTestData.questionTypes.has(SpeakingTestQuestionType.READING) && (
              <>
                <InstructionContentCardV2
                  imageUrl="/assets/images/speaking-test/text-reading.png"
                  title={t('assessment_speaking_test_instruction_instruction_content_card1_title')}
                />
                <hr className="w-3/4 mx-auto md:hidden" />
                <div className="border-solid border-l-[1px] border-gray-300 h-[125px] mt-12 hidden md:block"></div>
              </>
            )}

            {speakingTestData.questionTypes.has(SpeakingTestQuestionType.OPEN_ENDED) && (
              <>
                <InstructionContentCardV2
                  imageUrl="/assets/images/speaking-test/open-ended.png"
                  title={t('assessment_speaking_test_instruction_instruction_content_card2_title')}
                />
                <hr className="w-3/4 mx-auto md:hidden" />
                <div className="border-solid border-l-[1px] border-gray-300 h-[125px] mt-12 hidden md:block"></div>
              </>
            )}

            {speakingTestData.questionTypes.has(SpeakingTestQuestionType.DESCRIBE) && (
              <>
                <InstructionContentCardV2
                  imageUrl="/assets/images/speaking-test/describe.png"
                  title={t('assessment_speaking_test_instruction_instruction_content_card3_title')}
                />
              </>
            )}
          </div>
          <ul className="list-disc mb-6 px-4 text-gray-600 leading-loose">
            <li>
              <Text variant="sm" className="">
                {t('assessment_speaking_test_instruction_bottom_text_1')}
              </Text>
            </li>
            <li>
              <Text variant="sm" className="">
                {t('assessment_speaking_test_instruction_bottom_text_2')}
              </Text>
            </li>
          </ul>
          <Button variant="primary" onClick={handleStartSpeakingTest}>
            {t('assessment_speaking_test_instruction_button_start', { no: getPartNo() })}
          </Button>
          <ErrorModal
            open={error}
            title={t('assessment_speaking_test_instruction_error_modal_title')}
            description={t('assessment_speaking_test_instruction_error_modal_description')}
            buttonText={t('assessment_speaking_test_instruction_error_modal_button_text')}
            secondaryButtonText={t(
              'assessment_speaking_test_instruction_error_modal_secondar_button_text'
            )}
            onButtonClick={handleErrorModalButtonClick}
            onSecondaryButtonClick={handleErrorModalSecondaryButtonClick}
          />
          <LoadingModal open={loading} />
        </>
      )}
    </LayoutCard>
  );
};

export default SpeakingTestInstruction;
