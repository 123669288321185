import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiContext,
} from '@mui/x-data-grid';
import React, { useState } from 'react';
import { Button, Input, LoadingModal, Text } from '../../../../components/base';
import { formatNullableDate } from '../../../../utils/date';
import { AccessCodeStatusData } from '../AccessCodeStatus';
import ExportCSVConfirmationModal from './ExportCSVConfirmationModal';
import { createEnumTypeGuard } from '../../../../utils/validators';
import { CEFRLevel } from '../../../../types/cefr';
import { updateCustomCefr } from '../../../../api/admin';
import { gradeTestInstance } from '../../../../api/ai';

const isCEFRLevel = createEnumTypeGuard(CEFRLevel);
const CustomCefrInput = ({ testId }: { testId: string }) => {
  const [customCefr, setCustomCefr] = useState('');
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState('');

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCustomCefr(e.currentTarget.value);
  };

  const handleSubmit = async () => {
    if (isCEFRLevel(customCefr)) {
      try {
        setLoading(true);
        const response = await updateCustomCefr(testId, customCefr);
        const json = JSON.parse(response);
        const newCefr = json?.englishStandard?.cefr || '';
        setUpdated(newCefr);
      } catch (error) {
        console.error(error);
        alert('Cannot update.');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Invalid CEFR Level');
    }
  };

  const handleTryAgain = () => {
    setCustomCefr('');
    setUpdated('');
  };
  return (
    <div className={'text-left'}>
      {!!updated ? (
        <div className="flex items-center gap-2">
          <Text variant={'sm'}>Updated to {updated}</Text>
          <Button
            variant={'secondary'}
            size={'custom'}
            className={'py-2 px-3 text-2xs h-fit'}
            onClick={handleTryAgain}
          >
            Try Again?
          </Button>
        </div>
      ) : (
        <>
          <div className="flex items-center gap-2">
            <Input
              value={customCefr}
              onChange={handleChange}
              placeholder={'Add CEFR'}
              className={'!px-3 !py-1 text-xs w-[90px]'}
            />
            <Button
              variant={'primary'}
              size={'custom'}
              className={'py-2 px-3 text-2xs h-fit'}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
          <Text variant={'2xs'} className={'text-grey-500 mt-1'}>
            "PRE A1", "A1", "A2", "B1", "B2", "C1", "C2"
          </Text>
        </>
      )}

      <LoadingModal open={loading} />
    </div>
  );
};
interface AccessCodeStatusTableProps {
  accessCodeStatus: AccessCodeStatusData[];
}

const displayedDateFormat = 'DD MMM YY, HH:mm';

const columns: GridColDef<AccessCodeStatusData>[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hideable: false,
  },
  {
    field: 'accessCode',
    headerAlign: 'center',
    headerName: 'Access code',
    align: 'center',
    flex: 0.7,
    hideable: false,
  },
  {
    field: 'testerId',
    headerAlign: 'center',
    headerName: 'Tester Id',
    align: 'center',
    flex: 1.5,
  },
  {
    field: 'firstname',
    headerAlign: 'center',
    headerName: 'First name',
    align: 'center',
    flex: 0.7,
  },
  {
    field: 'lastname',
    headerAlign: 'center',
    headerName: 'Last name',
    align: 'center',
    flex: 0.7,
  },
  {
    field: 'testinstanceId',
    headerAlign: 'center',
    headerName: 'Testinstance Id',
    align: 'center',
    flex: 1.5,
  },
  {
    field: 'status',
    headerAlign: 'center',
    headerName: 'status',
    align: 'center',
    flex: 0.8,
  },
  {
    field: 'testInstanceCreatedAt',
    headerAlign: 'center',
    headerName: 'CreatedAt',
    align: 'center',
    flex: 0.7,
    renderCell: ({ value }) => formatNullableDate(value, displayedDateFormat, '-'),
  },
  {
    field: 'testInstanceUpdatedAt',
    headerAlign: 'center',
    headerName: 'UpdatedAt',
    align: 'center',
    flex: 0.7,
    renderCell: ({ value }) => formatNullableDate(value, displayedDateFormat, '-'),
  },
  {
    field: 'signedUrls',
    headerAlign: 'center',
    headerName: 'Signed Urls',
    align: 'center',
    flex: 1,
  },
  {
    field: 'result.englishStandard.cefr',
    headerAlign: 'center',
    headerName: 'CEFR',
    align: 'center',
    flex: 0.6,
    valueGetter: (params) => params.row?.result?.englishStandard.cefr,
  },
  {
    field: 'result.overall.score',
    headerAlign: 'center',
    headerName: 'Score',
    align: 'center',
    flex: 0.5,
    valueGetter: (params) => params.row?.result?.overall.score.toFixed(2),
  },
  {
    field: 'result.category.pronunciation.score',
    headerAlign: 'center',
    headerName: 'Pronunciation Score',
    align: 'center',
    flex: 0.5,
    valueGetter: (params) => params.row?.result?.category.pronunciation.score.toFixed(2),
  },
  {
    field: 'result.category.fluency.score',
    headerAlign: 'center',
    headerName: 'Fluency Score',
    align: 'center',
    flex: 0.5,
    valueGetter: (params) => params.row?.result?.category.fluency.score.toFixed(2),
  },
  {
    field: 'result.category.vocabulary.score',
    headerAlign: 'center',
    headerName: 'Vocab Score',
    align: 'center',
    flex: 0.5,
    valueGetter: (params) => params.row?.result?.category.vocabulary.score.toFixed(2),
  },
  {
    field: 'customCEFR',
    headerAlign: 'center',
    headerName: 'Custom CEFR',
    align: 'center',
    flex: 0.5,
  },
  {
    field: 'runAi',
    headerAlign: 'center',
    headerName: 'Grade',
    align: 'center',
    flex: 1.3,
    renderCell: (params) => {
      return <GradeTestInstanceButton testId={params.row.testinstanceId} />;
    },
  },
  {
    field: 'addCustomCEFR',
    headerAlign: 'center',
    headerName: 'Update Custom CEFR',
    align: 'center',
    flex: 1.3,
    renderCell: (params) => {
      return <CustomCefrInput testId={params.row.testinstanceId} />;
    },
  },
];

const AccessCodeStatusTable = ({ accessCodeStatus }: AccessCodeStatusTableProps) => {
  return (
    <div className="text-center" style={{ width: '100%', overflow: 'auto' }}>
      <div className="w-[2200px]">
        <DataGrid
          getRowId={(row) => `${row.testinstanceId}-${row.accessCode}`}
          rows={accessCodeStatus}
          columns={columns}
          disableRowSelectionOnClick
          checkboxSelection
          rowHeight={72}
          slots={{
            toolbar: CustomGridToolbar,
          }}
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cellContent': {
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              whiteSpace: 'initial',
              paddingY: 1,
            },
          }}
        />
      </div>
    </div>
  );
};

export default AccessCodeStatusTable;

const CustomGridToolbar = () => {
  const [open, setOpen] = useState(false);
  const apiRef = useGridApiContext();

  const selectedRowCount = apiRef.current.getSelectedRows().size;

  const onExportButtonClick = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <GridToolbarContainer>
      <div className="flex gap-x-2 px-2 py-1">
        <GridToolbarColumnsButton />
        <Button
          variant="secondary"
          onClick={onExportButtonClick}
          disabled={selectedRowCount === 0} // unless disabled, it will export all rows
        >
          Export CSV
        </Button>
      </div>
      <hr />
      <ExportCSVConfirmationModal open={open} onClose={handleModalClose} />
    </GridToolbarContainer>
  );
};

interface GradeTestInstanceButtonProps {
  testId: string;
}
const GradeTestInstanceButton: React.FC<GradeTestInstanceButtonProps> = ({ testId }) => {
  const [grading, setGrading] = React.useState(false);
  const [graded, setGraded] = React.useState(false);
  const handleClick = async () => {
    setGrading(true);
    try {
      await gradeTestInstance(testId);
      setGraded(true);
    } catch (error) {
      console.error(error);
    } finally {
      setGrading(false);
    }
  };

  if (graded) {
    return <Text className="text-green-400 font-bol">Graded</Text>;
  }
  return (
    <Button
      variant={'primary'}
      size={'custom'}
      className={'py-2 px-3 text-2xs h-fit'}
      onClick={handleClick}
      disabled={grading}
    >
      {grading ? 'grading...' : 'Grade'}
    </Button>
  );
};
